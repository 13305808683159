import { GET, POST, PUT } from '@/api';
import { APIConfig, AuctionDatesDTO, AuctionPricingUpdate, VehicleStatus } from '@/types';
import { applyAPIConfigOnSuccess, closeToast, dollarAmountToInt, formatErrorObj, openConfirmationDialog, openErrorDialog, openModal, OpenModalConfig, openToast } from '@/utils';
import { ref, SetupContext } from 'vue';

import ModalCreateOrder from '../components/ModalCreateOrder.vue';
import TheEditAuctionModal from '../components/TheEditAuctionModal.vue';

type EditAuctionProps = AuctionPricingUpdate & AuctionDatesDTO & { vehicleListingId: number };

export function useEditAuctionModal() {
    const loadingFetchAuctionDetails = ref(false);

    async function fetchAuctionDetails(vehicleListingId: number) {
        loadingFetchAuctionDetails.value = true;
        let toast = openToast('is-success', 'Fetching auction details...', 'indefinite');
        return await GET(`/vehicles/${vehicleListingId}/getAuctionDetails`)
            .then(res => {
                loadingFetchAuctionDetails.value = false;
                closeToast(toast);
                return (({ startingPrice, reserve, bids, buyItNowPrice, canBuyItNow }) => ({ startingPrice, reservePrice: reserve, numBids: bids, buyItNowPrice, canBuyItNow }))(res.data);
            }).catch(error => {
                loadingFetchAuctionDetails.value = false;
                closeToast(toast);
                openErrorDialog({
                    title: 'Failed to fetch auction details',
                    message: `We encountered an error while getting auction details for vehicle ${vehicleListingId}`,
                    error,
                });
            });
    }

    async function openEditAuctionModal({ modalProps, shouldFetchAuctionDetails, modalConfig }: { 
        modalProps: Partial<EditAuctionProps> & { vehicleListingId: number }, 
        shouldFetchAuctionDetails: boolean,
        modalConfig?: OpenModalConfig,
    }) {
        let props = modalProps;
        if (shouldFetchAuctionDetails) {
            let auctionDetails = await fetchAuctionDetails(modalProps.vehicleListingId);
            props = {
                ...modalProps,
                ...auctionDetails,
            }
        }
        openModal({
            component: TheEditAuctionModal,
            props,
            ...modalConfig,
        });
    }

    async function openCreateOrderModal(vehicleListingId: number, marketplaceListingId?: number) {
        openModal({
            component: ModalCreateOrder,
            props: {
                vehicleListingId,
                marketplaceListingId,
            }
        });
    }

    return {
        openEditAuctionModal,
        openCreateOrderModal,
        loadingFetchAuctionDetails,
    }
}

export function useSaveAuctionUpdates() {
    async function saveAuctionPricing(vehicleListingId: number, pricing: AuctionPricingUpdate) {
        await PUT(`inspection/saveBasicInfo/${vehicleListingId}`, {
            ...pricing,
            canBuyItNowObject: pricing,
        }).then(res => res.data)
            .catch(error => {
                openErrorDialog({
                    title: 'Failed to save auction updates',
                    message: `
                        <div>
                            <p>We encountered an error while saving auction updates for vehicle ${vehicleListingId}<p>
                            <p class="has-text-danger">
                                Changes attempted: <br>
                                ${formatErrorObj(pricing)}
                            </p>
                        </div>
                    `,
                    error,
                });
                throw error;
            });
    }

    async function saveAuctionDates(
        vehicleListingId: number, 
        auctionDates: { 
            auctionStart: Date, 
            auctionEnd: Date
    }) {
        await POST(`vehicles/date`, {
            vehicleIds: [vehicleListingId],
            auctionStart: auctionDates.auctionStart,
            auctionEnd: auctionDates.auctionEnd,
        }).then(res => res.data)
        .catch(error => {
            openErrorDialog({
                title: 'Failed to save auction updates',
                message: `
                    <div>
                        <p>We encountered an error while saving auction updates for vehicle ${vehicleListingId}<p>
                        <p class="has-text-danger">
                            Changes attempted: <br>
                            ${formatErrorObj(auctionDates)}
                        </p>
                    </div>
                `,
                error,
            });
            throw error;
        });
    }

    async function saveAuctionUpdates(
        updatedAuction: AuctionPricingUpdate & AuctionDatesDTO,
        props: EditAuctionProps, 
        context: SetupContext<('toggleLoading' | 'savedUpdates')[]>
    ) {
        context.emit('toggleLoading', true);
        let promises = [];

        // only save pricing fields if there are updates
        let hasPricingUpdates = checkForUpdates({ 
            updatedAuction, 
            originalAuction: props, 
            checkFields: ['startingPrice', 'reservePrice', 'buyItNowPrice', 'canBuyItNow'],
            currencyFields: ['startingPrice', 'reservePrice', 'buyItNowPrice']
        });

        if (hasPricingUpdates) {
            promises.push(saveAuctionPricing(
                props.vehicleListingId,
                {
                    startingPrice: updatedAuction.startingPrice,
                    reservePrice: updatedAuction.reservePrice,
                    buyItNowPrice: updatedAuction.buyItNowPrice,
                    canBuyItNow: updatedAuction.canBuyItNow,
                }
            ));
        }

        // only save auction date fields if there are updates
        let hasDateUpdates = checkForUpdates({
            updatedAuction,
            originalAuction: props,
            checkFields: ['auctionStart', 'auctionEnd'],
        });

        if (hasDateUpdates) {
            promises.push(saveAuctionDates(props.vehicleListingId, {
                auctionStart: updatedAuction.auctionStart!,
                auctionEnd: updatedAuction.auctionEnd!,
            }));
        }

        await Promise.all(promises).then(res => {
            context.emit('toggleLoading', false);
            context.emit('savedUpdates', updatedAuction);
            openToast('is-success', 'Auction updates saved!');
        }).catch(error => {
            context.emit('toggleLoading', false);
        });
    }


    function checkForUpdates({ updatedAuction, originalAuction, checkFields, currencyFields }: {
        updatedAuction: AuctionPricingUpdate & AuctionDatesDTO, 
        originalAuction: EditAuctionProps,
        checkFields: string[],
        currencyFields?: string[],
    }) {
        return checkFields.some(field => {
            let updatedValue = updatedAuction[field];
            if (currencyFields?.includes(field)) {
                updatedValue = dollarAmountToInt(updatedValue);
            }
            return updatedValue !== originalAuction[field];
        });
    }

    return {
        saveAuctionPricing,
        saveAuctionDates,
        saveAuctionUpdates,
    }
}

export function useMarkAsNotSold({ vehicleListingId, vehicleStatus, yearMakeModel, marketplaceListingId, isSecondChance, context }: { 
    vehicleListingId?: number,
    vehicleStatus?: VehicleStatus,
    yearMakeModel?: string,
    marketplaceListingId?: number,
    isSecondChance?: boolean,
    context?: SetupContext<('markedNotSold' | any)[]>
}={}) {
    function openMarkAsNotSoldDialog(config: APIConfig={}) {
        if (!vehicleListingId) {
            return openErrorDialog({
                title: 'Could not mark as Not Sold',
                message: `A vehicle listing ID was not provided`, 
            });
        }
        openConfirmationDialog({
            title: 'Mark as Not Sold?',
            message: `Are you sure you want to mark this ${yearMakeModel ?? 'vehicle'} (ID: ${vehicleListingId}) as Not Sold?`,
            onConfirm: () => markAsNotSold({ vehicleListingId, marketplaceListingId, isSecondChance }, config),
            onCancel: () => {},
        });
    }

    const loadingBulkMarkAsNotSold = ref(false);
    async function bulkMarkAsNotSold(listings: Array<{ vehicleListingId: number, marketplaceListingId?: number, isSecondChance?: boolean }>) {
        loadingBulkMarkAsNotSold.value = true;
        
        await Promise.all(listings.map(async ({ vehicleListingId, marketplaceListingId, isSecondChance }) => {
            await markAsNotSold({
                vehicleListingId,
                isSecondChance,
                marketplaceListingId,
                vehicleStatus,
            });
        })).then(() => {
            loadingBulkMarkAsNotSold.value = false;
            context?.emit('markedNotSold');
        }).catch(error => {
            loadingBulkMarkAsNotSold.value = false;
        });
    }

    const statusesWithChecklist: VehicleStatus[] = ['Checkout', 'Sold', 'Completed'];
    async function markAsNotSold({ vehicleListingId, vehicleStatus, marketplaceListingId, isSecondChance }: {
        vehicleListingId: number,
        vehicleStatus?: VehicleStatus,
        marketplaceListingId?: number,
        isSecondChance?: boolean,
    }, config: APIConfig={}) {
        const vehicleHasChecklist = vehicleStatus ? statusesWithChecklist.includes(vehicleStatus) : false;
        vehicleHasChecklist 
            ? await markAsNotSoldWithChecklist(vehicleListingId, config) 
            : await markAsNotSoldWithoutChecklist({ vehicleListingId, marketplaceListingId, isSecondChance }, config);
    }

    const loadingMarkAsNotSold = ref(false);
    async function markAsNotSoldWithoutChecklist({ vehicleListingId, marketplaceListingId, isSecondChance }: {
        vehicleListingId: number,
        marketplaceListingId?: number,
        isSecondChance?: boolean,
    }, config: APIConfig={}) {
        let toast = openToast('is-success', 'Updating vehicle status...', 'indefinite');
        loadingMarkAsNotSold.value = true;
        let notSoldStatus = 'NotSold';
        if (isSecondChance) {
            notSoldStatus = 'SecondChanceNotSold';
        } else if (marketplaceListingId) {
            notSoldStatus = 'MarketplaceNotSold';
        }
        await PUT(`vehicles/updateStatus`, {
            vehicleId: vehicleListingId.toString(),
            status: notSoldStatus,
        }).then(res => {
            loadingMarkAsNotSold.value = false;
            context?.emit('markedNotSold');
            closeToast(toast);
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            loadingMarkAsNotSold.value = false;
            closeToast(toast);
            openErrorDialog({
                title: 'Failed to update status',
                message: `We encountered an error while updating the status of vehicle ${vehicleListingId} to ${notSoldStatus}`,
                error,
            });
        });
    }

    async function markAsNotSoldWithChecklist(listingId: number, config: APIConfig={}) {
        let toast = openToast('is-success', 'Updating vehicle status...', 'indefinite');
        loadingMarkAsNotSold.value = true;
        await PUT(`vehicles/setChecklistVehicleToNotSold/${listingId}`)
        .then(res => {
            loadingMarkAsNotSold.value = false;
            context?.emit('markedNotSold');
            closeToast(toast);
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            loadingMarkAsNotSold.value = false;
            closeToast(toast);
            openErrorDialog({
                title: 'Failed to update status',
                message: `We encountered an error while updating the status of vehicle ${listingId} (with checklist) to 'Not Sold'`,
                error,
            });
        });
    }

    return {
        openMarkAsNotSoldDialog,
        markAsNotSold,
        loadingMarkAsNotSold,
        bulkMarkAsNotSold,
        loadingBulkMarkAsNotSold,
    }
}
