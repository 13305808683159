import { getArbitrationRateBySellerStoreId, getSellRateBySellerStoreId, getTimeToTitleBySellerStoreId } from "@/api";
import { ListingRowTableType, SellerStat, SRPListing, TimeDTO } from "@/types";
import { formatSellerStat, UpdateUrlParams } from "@/utils";
import { computed, onBeforeMount, Ref, ref, SetupContext, watch } from "vue";
import { useRoute } from 'vue-router/composables';
import { useCancelToken, useFetch } from './fetch';
import { useStore } from "./useStore";

export interface UpdateListingsCallbackParams {
    listings: any[],
    fromFilterUpdate?: boolean
}

export function useGetListingTypeFromUrlParams(validListingTypes: string[]) {
    let route = useRoute();
    
    let urlParams = route.query;
    
    // GET LISTING TYPE
    const urlListingType: Ref<ListingType> = ref('auction'); 
    if (validListingTypes.includes(urlParams.listingType as string)) {
        urlListingType.value = urlParams.listingType as ListingType;
    }

    return {
        urlListingType,
    }
}

export type ListingType = 'auction' | 'marketplace' | 'secondChance';

export function useListingType({ context, updateUrlParams, initialListingType, validListingTypes}: {
    context: SetupContext<('update:listingType' | 'selectedListingType' | 'updateVehicleStatus' | any)[]>,
    updateUrlParams?: ({}: UpdateUrlParams) => void, // leave undefined if you don't want to update URL params
    initialListingType?: ListingType | ListingRowTableType,
    validListingTypes: string[],
}) {
    const selectedListingType: Ref<ListingType | ListingRowTableType> = ref(initialListingType ?? 'auction');
    const route = useRoute();
    const listingTypeKey = ref(0);

    function updateSelectedListingType(listingType: ListingType) {
        selectedListingType.value = listingType;
    }

    watch(() => selectedListingType.value, () => {
        context.emit('update:listingType', selectedListingType.value);
        context.emit('updateVehicleStatus', getVehicleStatusFromListingType.value);
        if (updateUrlParams) {
            updateUrlParams({
                route, 
                newQueryParams: { 'listingType': selectedListingType.value }, 
                maintainAllParams: true,
            });
        }
    });

    const { urlListingType } = useGetListingTypeFromUrlParams(validListingTypes);
    onBeforeMount(() => {
        selectedListingType.value = urlListingType.value ?? 'auction';
    });

    const getVehicleStatusFromListingType = computed(() => {
        let status;
        switch (selectedListingType.value) {
            case 'auction':
                status = 'Auctioning';
                break;
            case 'marketplace':
                status = 'InMarketplace';
                break;
            case 'secondChance':
                status = 'SecondChance';
                break;
        }
        return status;
    });

    return {
        selectedListingType,
        updateSelectedListingType,
        listingTypeKey,
        getVehicleStatusFromListingType,
    }
}

export function useRemoveListingRow(listings: Ref<SRPListing[]>) {
    function removeListingRow(vehicleListingId: number) {
        window.setTimeout(() => {
            listings.value = listings.value.filter(listing => listing.id !== vehicleListingId);
        }, 500);
    }

    const removeListingRowQueue: Ref<number[]> = ref([]);
    function queueRemoveListingRow(vehicleListingId: number) {
        removeListingRowQueue.value.push(vehicleListingId);
    }

    function executeRemoveListingRowQueue() {
        removeListingRowQueue.value.forEach(vehicleListingId => {
            removeListingRow(vehicleListingId);
        });
    }

    return {
        removeListingRow,
        removeListingRowQueue,
        queueRemoveListingRow,
        executeRemoveListingRowQueue,
    }
}

export function useTransporationCost({ listingZipCode, vehicleListingId, context }: { 
    listingZipCode?: string, 
    vehicleListingId: number,
    context?: SetupContext<( 'transportationCost' | any )[]>,
}) {
    const store = useStore();
    const zip1 = store.getters.getTransportationZip;
    if (!listingZipCode || !zip1) {
        return {
            transportationCost: ref(undefined),
            isLoadingTransportationCost: ref(false),
        }
    }

    const { cancelToken, createNewCancelToken } = useCancelToken();
    createNewCancelToken();

    const {
        data,
        loading,
    } = useFetch<number>(`/distance/calculateTransportationCostUsingZipCodes`, {
            method: 'PUT',
            data: {
                zip1, 
                zip2: listingZipCode,
                vehicleListingId,
            },
            cancelToken: cancelToken.value?.token,
            skipErrorOnCancel: true,
            onSuccess: () => {
                    if (context) {
                        context.emit('transportationCost', data.value);
                    }
                }
            });

    return {
        transportationCost: data,
        isLoadingTransportationCost: loading,
    };
}

export function useFetchSellingStat({ sellerStoreId, statName }: {
    sellerStoreId: number,
    statName: SellerStat,
}) {
    const sellingStat: Ref<number | string | undefined> = ref(undefined);
    const loadingStat: Ref<boolean> = ref(false);

    const fetchStatFunction: { [key: string]: (sellerStoreId: number) => Promise<number | TimeDTO | undefined>} = {
        'Arbitration Rate': getArbitrationRateBySellerStoreId,
        'Sell Rate': getSellRateBySellerStoreId,
        'Time to Title': getTimeToTitleBySellerStoreId,
    }

    onBeforeMount(async () => {
        loadingStat.value = true;
        let fetchFunction = fetchStatFunction[statName];
        if (!fetchFunction) {
            loadingStat.value = false;
            return;
        }
        await fetchFunction(sellerStoreId)
            .then(res => {
                sellingStat.value = formatSellerStat(statName, res);
                loadingStat.value = false;
            }).catch(error => {
                loadingStat.value = false;
                console.log('error', error);
            });
    });
    return {
        sellingStat,
        loadingStat,
    }
}
