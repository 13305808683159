var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{staticClass:"vdp",class:{ 'is-in-modal': _vm.$attrs.isInModal}},[_c('div',{staticClass:"vdp-listing-container",class:{ 'has-nav-link': _vm.store?.state?.lastPath }},[_vm._t("buttons"),(!_vm.$attrs.isInModal && (_vm.store?.state?.lastPath || _vm.router.app.$route.name == 'Inspection Details'))?_c('div',{staticClass:"nav-link-container"},[_c('NavLinkWithAnimation',{attrs:{"linkText":_vm.getLastPathName()},on:{"click":_vm.goToLastPath}})],1):_vm._e(),(_vm.displayOverdrive)?_c('AppOverdrive',_vm._b({staticClass:"p-2 pl-4"},'AppOverdrive',{
                confettiDirection: 'in',
            },false)):_vm._e(),_c('div',{staticClass:"vdp-listing-body columns px-2"},[_c('div',{staticClass:"vehicle-overview column is-4 pl-4"},[_vm._t("images",function(){return [_vm._t("carouselButtons"),_c('div',{staticClass:"vehicle-graphic width-100"},[(_vm.isGalleryView)?_c('b-image',{attrs:{"src":_vm.listing.heroImgUrl ?? require(`@/assets/defaultimg.jpg`)}}):_vm._e(),_c('AppCarouselPhotos',_vm._b({key:`carousel-${_vm.displayVehicleListingPhotoId}-${_vm.isGalleryView}`,class:{ 'gallery-view': _vm.isGalleryView },attrs:{"selectedPhotoId":_vm.displayVehicleListingPhotoId},on:{"update:selectedPhotoId":function($event){_vm.displayVehicleListingPhotoId=$event},"update:selected-photo-id":function($event){_vm.displayVehicleListingPhotoId=$event},"toggleGalleryView":function($event){_vm.isGalleryView = $event}},scopedSlots:_vm._u([{key:"caption",fn:function({ item }){return [(item.announcement)?_c('a',{staticClass:"photo-announcement-container position-absolute",on:{"click":function($event){_vm.isGalleryView = true}}},[_c('TheAnnouncement',_vm._b({staticClass:"photo-announcement has-background-white has-text-dark"},'TheAnnouncement',{
                                            announcement: item.announcement,
                                            isClickable: false,
                                            isEditable: false,
                                        },false))],1):_vm._e()]}},{key:"list-item",fn:function(item){return [_c('div',{staticClass:"position-relative"},[(item.announcement)?_c('b-icon',{staticClass:"announcement-icon position-absolute",class:_vm.getIconColorByAnnouncementType(item.announcement.announcementType),attrs:{"icon":"alert-circle","size":"is-small"}}):_vm._e(),_c('figure',{staticClass:"b-image-wrapper image",attrs:{"url":item.image ?? require(`@/assets/defaultimg.jpg`)}},[_c('img',{attrs:{"src":item.image ?? require(`@/assets/defaultimg.jpg`),"loading":"lazy"}})])],1)]}}])},'AppCarouselPhotos',{
                                items: _vm.getVehiclePhotos,
                                animated: 'fade', // this is disabled by the remove-nav-animations class
                                autoplay: false,
                                indicator: false,
                                withCarouselList: _vm.isGalleryView,
                                isGalleryView: _vm.isGalleryView,
                                canExpandGallery: true,
                                enableZoom: _vm.isGalleryView,
                                filterKeyboardNavigationEvent: _vm.filterKeyboardNavigationEvent,
                                ..._vm.$attrs.carouselAttrs,
                                class: 'app-listing-details__carousel remove-nav-animations'
                            },false)),(_vm.highestBid?.reserveMet && _vm.highestBid?.status == 'Auctioning')?_c('TheReserveMetFlag',{staticClass:"position-absolute"}):_vm._e()],1)]}),_vm._t("vehicleBasics",function(){return [(_vm.listing)?_c('TheListingDetailsVehicleBasics',_vm._b({on:{"share":_vm.shareListing},scopedSlots:_vm._u([{key:"locationAdditionalDetails",fn:function(){return [(_vm.isLoggedIn)?_c('TheListingDetailsViewsAndCounts',_vm._b({staticClass:"views-and-counts-mobile flex-row has-text-dark"},'TheListingDetailsViewsAndCounts',{
                                    viewCount: _vm.highestBid?.views,
                                    bidCount: _vm.highestBid?.bids,
                                },false)):_vm._e()]},proxy:true},{key:"additionalDetails",fn:function(){return [(_vm.listing.inoperable)?_c('TheInoperableListingFlag',{staticClass:"mt-2"}):_vm._e(),((_vm.purchasedVehicle || _vm.isUserAdmin || _vm.isUserDsr) && _vm.isVehicleStatusCheckoutOrLater(_vm.highestBid?.status ?? _vm.listing.status))?_c('TheTitleAndDeliveryStatusIcons',_vm._b({staticClass:"mt-3"},'TheTitleAndDeliveryStatusIcons',{
                                    vehicleListingId: _vm.listing.id,
                                    facilitatingAuctionStoreName: _vm.listing.facilitatingAuction?.name,
                                },false)):_vm._e()]},proxy:true},{key:"carmigoInspected",fn:function(){return [(_vm.listing.inspectorPersonId)?_c('b-tag',{staticClass:"is-primary"},[_vm._v("Carmigo Inspected")]):_vm._e()]},proxy:true}],null,false,1844580835)},'TheListingDetailsVehicleBasics',{
                            showShareListingButton: !_vm.$attrs.isInModal,
                            year: _vm.listing.year,
                            make: _vm.listing.brand,
                            model: _vm.listing.model,
                            trim: _vm.listing.trim,
                            vin: _vm.listing.vin,
                            miles: _vm.listing.mileage,
                            city: _vm.listing.city,
                            state: _vm.listing.state,
                            estimatedShippingInDollars: _vm.listing.transportationCost ?? _vm.transportationCostCalculated,
                        },false)):_vm._e()]}),_vm._t("vehicleAttributes",function(){return [(_vm.listing)?_c('TheListingDetailsVehicleAttributes',_vm._b({staticClass:"flex-row align-center mt-2"},'TheListingDetailsVehicleAttributes',{
                            isInoperable: _vm.listing.isInoperable,
                            isWholesale: _vm.listing.isWholesale,
                            isFrontline: _vm.listing.isFrontline,
                        },false)):_vm._e()]}),_c('div',{staticClass:"mt-4 py-2 has-background-white seller-details"},[_vm._t("sellerDetails",function(){return [(_vm.listing)?_c('TheListingDetailsSellerDetails',_vm._b({},'TheListingDetailsSellerDetails',{
                                titleStatus: _vm.listing.titleStatus,
                                sellerType: _vm.listing.sellerType,
                                store: _vm.listing.sellerStore?.name,
                                facilitatingAuctionStoreName: _vm.listing.facilitatingAuction?.name,
                                sellerStoreId: _vm.listing.sellerStore?.id,
                            },false)):_vm._e()]})],2)],2),_c('div',{staticClass:"column is-8"},[_c('div',{staticClass:"stats-and-buttons"},[_c('div',{staticClass:"listing-stats-container py-2 px-3"},[_vm._t("listingStats",function(){return [_c('TheListingDetailsListingStats',_vm._b({},'TheListingDetailsListingStats',{
                                    priceLabel: _vm.isVehicleStatusCheckoutOrLater(_vm.highestBid?.status) ? 'Sold for' : 'Current Bid',
                                    views: _vm.highestBid?.views,
                                    bids: _vm.highestBid?.bids,
                                    date: _vm.highestBid?.status == 'Auctioning' ? new Date(_vm.highestBid.auctionEnd) : undefined,
                                    highestBid: _vm.highestBid,
                                    price: _vm.order?.vehiclePrice,
                                    orderBuyerPersonId: _vm.order?.buyerPersonId,
                                },false))]})],2),_c('div',{staticClass:"mt-3 mb-4 listing-buttons"},[_vm._t("listingButtons")],2)]),_c('div',{staticClass:"columns listing-cards"},[_c('div',{staticClass:"column is-6"},[_vm._t("sellerDetailsMobile",function(){return [(_vm.listing)?_c('TheListingDetailsSellerDetails',_vm._b({staticClass:"seller-details-mobile"},'TheListingDetailsSellerDetails',{
                                    titleStatus: _vm.listing.titleStatus,
                                    sellerType: _vm.listing.sellerType,
                                    store: _vm.listing.sellerStore?.name,
                                    facilitatingAuctionStoreName: _vm.listing.facilitatingAuction?.name,
                                    sellerStoreId: _vm.listing.sellerStore?.id,
                                },false)):_vm._e()]}),(_vm.bulmaBreakpoint.mobile)?_vm._t("buyerNotes"):_vm._e(),_vm._t("announcements",function(){return [_c('TheListingDetailsAnnouncements',_vm._b({on:{"viewPhoto":function($event){return _vm.showAnnouncementPhoto($event)}}},'TheListingDetailsAnnouncements',{
                                    loading: _vm.$attrs.loadingAnnouncements,
                                    announcements: _vm.announcements,
                                    numAnnouncements: _vm.$props.listing?.announcementCount ? Number(_vm.$props.listing.announcementCount) : undefined,
                                },false))]}),_vm._t("bookoutOptions",function(){return [_c('TheListingDetailsBookoutOptions',_vm._b({},'TheListingDetailsBookoutOptions',{
                                    loading: _vm.$attrs.loadingBookoutOptions,
                                    bookoutOptions: _vm.bookoutOptions,
                                },false))]})],2),_c('div',{staticClass:"column is-6"},[(!_vm.bulmaBreakpoint.mobile)?_vm._t("buyerNotes"):_vm._e(),_vm._t("bidHistory",function(){return [_c('TheListingDetailsBidHistory',_vm._b({},'TheListingDetailsBidHistory',{
                                    loading: _vm.$attrs.loadingBidHistory,
                                    bidHistory: _vm.bidHistory,
                                    highestBid: _vm.highestBid,
                                    reservePrice: _vm.listing?.auction?.reservePrice,
                                },false))]})],2)])])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }