var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppListingDetails',_vm._b({scopedSlots:_vm._u([{key:"buyerNotes",fn:function(){return [_c('TheListingDetailsCard',{attrs:{"loading":_vm.loadingBuyerNotes},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex-row align-center"},[_c('b-icon',{attrs:{"icon":"comment-text"}}),_c('h2',{staticClass:"ml-2"},[_vm._v("Your Notes")])],1)]},proxy:true},{key:"body",fn:function(){return [_c('TheListingBuyerNotes',_vm._b({attrs:{"loadingBuyerNotes":_vm.loadingBuyerNotes},on:{"update:loadingBuyerNotes":function($event){_vm.loadingBuyerNotes=$event},"update:loading-buyer-notes":function($event){_vm.loadingBuyerNotes=$event}}},'TheListingBuyerNotes',{
                        vehicleListingId: _vm.$props.listing.id,
                        useAddNoteButton: _vm.bulmaBreakpoint.mobile,
                        vehicleName: `${_vm.$props.listing.year} ${_vm.$props.listing.brand} ${_vm.$props.listing.model}`,
                        vin: _vm.$props.listing.vin,
                        imageUrl: _vm.$props.listing.heroImgUrl,
                    },false))]},proxy:true}])})]},proxy:true},{key:"listingButtons",fn:function(){return [_c('div',{staticClass:"width-100"},[(_vm.highestBid?.status == 'Auctioning')?_c('div',{staticClass:"flex-row align-center"},[_c('BiddingButtons',_vm._b({on:{"onConfirmBid":function($event){_vm.listing.watching = true; _vm.updateWatchlistStatus(true)},"getProxyBid":function($event){_vm.listing.watching = true; _vm.updateWatchlistStatus(true)}}},'BiddingButtons',{
                        vehicle: _vm.listing,
                        buyItNowPrice: _vm.listing.auction?.buyItNowPrice,
                        reservePrice: _vm.listing.auction?.reservePrice,
                        highestBid: _vm.highestBid,
                        size: 'is-medium'
                    },false)),_c('TheListingWatchlistButton',{staticStyle:{"margin-top":"0.5rem"},attrs:{"isToggled":_vm.listing.watching,"size":"is-medium"},on:{"update:isToggled":function($event){return _vm.$set(_vm.listing, "watching", $event)},"update:is-toggled":function($event){return _vm.$set(_vm.listing, "watching", $event)},"toggle":_vm.updateWatchlistStatus}})],1):(_vm.highestBid?.status == 'Negotiating')?_c('TheNegotiateButtonAuction',_vm._b({},'TheNegotiateButtonAuction',{
                    vehicleListingId: _vm.listing.id,
                    vin: _vm.listing.vin,
                    negotiationOffer: _vm.negotiationOffer,
                    loading: _vm.loadingNegotiationOffer,
                    size: 'is-medium',
                },false)):(_vm.purchasedVehicle && _vm.highestBid.status == 'Checkout')?_c('TheCheckoutButton',_vm._b({staticClass:"checkout-button"},'TheCheckoutButton',{
                    vehicleListingId: _vm.listing.id,
                    vehicleStatus: _vm.highestBid.status,
                    vehicleName: `${_vm.listing.year} ${_vm.listing.brand} ${_vm.listing.model}`,
                    vehiclePrice: _vm.order.vehiclePrice,
                    facilitatingAuctionStoreName: _vm.listing.facilitatingAuction?.name,
                    size: 'is-medium',
                    outlined: false,
                },false)):(_vm.purchasedVehicle && _vm.isVehicleSold(_vm.highestBid.status))?_c('div',{staticClass:"buttons width-100"},[_c('ViewOrderSummaryButton',_vm._b({},'ViewOrderSummaryButton',{
                        vehicleListingId: _vm.listing.id,
                        vehicleStatus: _vm.highestBid.status,
                        vehicleName: `${_vm.listing.year} ${_vm.listing.brand} ${_vm.listing.model}`,
                        size: 'is-medium',
                        outlined: false,
                    },false)),(!_vm.listing.facilitatingAuction && _vm.arbitrationDetails)?_c('TheOpenArbitrationClaimButton',_vm._b({},'TheOpenArbitrationClaimButton',{
                        vehicleListingId: _vm.listing.id,
                        ..._vm.arbitrationDetails,
                        hasOpenedClaim: Boolean(_vm.arbitrationDetails.openedArbitrationClaimId),
                        size: 'is-medium',
                        showOpenedClaimDetails: true,
                        arbitrationExpirationDate: _vm.order?.arbitrationExpirationDate ? new Date(_vm.order.arbitrationExpirationDate) : undefined,
                    },false)):_vm._e()],1):_vm._e()],1)]},proxy:true}])},'AppListingDetails',{ 
        listing: _vm.listing,
        vehiclePhotos: _vm.vehiclePhotos,
        announcements: _vm.announcements,
        loadingAnnouncements: _vm.loadingAnnouncements,
        bookoutOptions: _vm.bookoutOptions,
        loadingBookoutOptions: _vm.loadingBookoutOptions,
        bidHistory: _vm.bidHistory,
        loadingBidHistory: _vm.loadingBidHistory,
        order: _vm.order,
        highestBid: _vm.highestBid,
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }