import { POST } from "@/api";
import {
    Filter,
    FilterField,
    FilterFieldConfig,
    FilterFieldName,
    FilterFieldOption,
    FilterFieldOptionBoolean,
    FilterFieldOptionRange,
    FilterFieldOptionsSchema,
    FilterFieldOptionsSchemaConfig,
    FilterInputType,
    FilterSchema,
    InputMask,
    ModifierOptionsFilterObject,
    NotificationSetting,
    SavedFilter,
    SelectedFilters,
    UserSavedFilterDTO,
    VehicleFilter,
    VehicleFilterPreset,
    VehicleStatus,
} from '@/types';
import { Route } from "@sentry/vue/types-ts3.8/router";
import _, { isEqual } from "lodash";
import { toCurrency } from "./priceUtils";
import { updateUrlParams } from "./routerUtils";
import { fireEvent } from "@/segment";
import router from '@/router'
import store from '@/vuex';


export function normalizeFilterNotifications(notifications: { email: boolean, text: boolean }): NotificationSetting[] {
    return [
        {
            modality: 'email',
            enabled: notifications?.email ?? false,
        },
        {
            modality: 'sms',
            enabled: notifications?.text ?? false,
        }
    ]
}

export function getFilterFieldsWithFilterValues(filters: VehicleFilter): Array<keyof VehicleFilter> {
    return Object.keys(filters).filter((filterField) => {
        const filterValue = filters[filterField as keyof VehicleFilter];
        return typeof filterValue == 'object' ? Boolean(filterValue?.length) : Boolean(filterValue);
    }) as Array<keyof VehicleFilter>;
}

export function clearFilterField(filters: VehicleFilter, fieldToClear: keyof VehicleFilter) {
    const currValue = filters[fieldToClear];
    switch (typeof currValue) {
        case 'object':
            // @ts-ignore
            filters[fieldToClear] = [];
            break;
        case 'string':
            // @ts-ignore
            filters[fieldToClear] = '';
            break;
        case 'number':
            // @ts-ignore
            filters[fieldToClear] = 0;
            break;
        case 'boolean':
            // @ts-ignore
            filters[fieldToClear] = false;
            break;
    }

    if (fieldToClear == 'distance') {
        filters.zip = '';
    }
    return filters;
}


export function omitFilterFields({ filters, fieldsToOmit }: {
    filters?: Filter, 
    fieldsToOmit: FilterFieldName[],
}): Filter {
    if (!filters) {
        return {};
    }
    let filtersWithFieldsOmitted = filters;
    fieldsToOmit.forEach(fieldName => {
        filtersWithFieldsOmitted = _.omit(filters, fieldName);
    });
    return filtersWithFieldsOmitted;
}


type TagInput = {
    label: string,
    value: string
}
type VehicleFilterWithTags = {
    [key: string]: TagInput[] | string[] | number[] | number | string | null | boolean,
}


export function getBooleanFilterValueByField(field: FilterFieldName, selectedOptions: SelectedFilters) {
  return selectedOptions[field]?.options?.length ? (selectedOptions[field]!.options![0] as FilterFieldOptionBoolean).value : false;
}

export function getFilterFieldConfigs(selectedFilters: SelectedFilters, filters: Filter) {
    let configs: { [key in FilterFieldName]?: FilterFieldConfig} = {};
    Object.keys(selectedFilters).forEach((filterField) => {
        configs[filterField as FilterFieldName] = filters[filterField as FilterFieldName]?.config;
    });
    return configs;
}

export function convertUserSavedFilterToSavedFilter(userSavedFilterDTO: UserSavedFilterDTO, filters: Filter, dependentFields: Filter): SavedFilter {
    let filterAsUserSavedFilterDTO: { [key: string]: any } = userSavedFilterDTO.filter;

    let filterAsSelectedFilterDTO: SelectedFilters = {
        sellerType: {
            ...filters.sellerType,
            options: mapStringArrayToFilterFieldOptionArray(filterAsUserSavedFilterDTO.sellerType),
        },
        sellerStore: {
            ...filters.sellerStore,
            options: mapStringArrayToFilterFieldOptionArray(filterAsUserSavedFilterDTO.sellerStore),
        },
        make: {
            ...filters.make,
            options: mapStringArrayToFilterFieldOptionArray(filterAsUserSavedFilterDTO.make),
        },
        model: {
            ...filters.model,
            options: mapStringArrayToFilterFieldOptionArray(filterAsUserSavedFilterDTO.model),
        },
        bodyType: {
            ...filters.bodyType,
            options: mapStringArrayToFilterFieldOptionArray(filterAsUserSavedFilterDTO.bodyType),
        },
        trim: {
            ...filters.trim,
            options: mapStringArrayToFilterFieldOptionArray(filterAsUserSavedFilterDTO.trim),
        },
        fuelType: {
            ...filters.fuelType,
            options: mapStringArrayToFilterFieldOptionArray(filterAsUserSavedFilterDTO.fuelType),
        },
        year: {
            ...filters.year,
            options: getMinMaxAsFilterFieldOptionRangeArray(filterAsUserSavedFilterDTO.minYear, filterAsUserSavedFilterDTO.maxYear),
        },
        distance: {
            ...filters.distance,
            options: getMinMaxAsFilterFieldOptionRangeArray(0, filterAsUserSavedFilterDTO.distance),
            config: {
                ...filters.distance!.config,
                dependentFields: [
                    {
                        ...dependentFields.zip as FilterField,
                        options: [{ displayName: 'zip', value: filterAsUserSavedFilterDTO.distanceZip }]
                    }
                ]
            }
        },
        price: {
            ...filters.price,
            options: getMinMaxAsFilterFieldOptionRangeArray(filterAsUserSavedFilterDTO.minPrice, filterAsUserSavedFilterDTO.maxPrice),
        },
        mileage: {
            ...filters.mileage,
            options: getMinMaxAsFilterFieldOptionRangeArray(filterAsUserSavedFilterDTO.minMileage, filterAsUserSavedFilterDTO.maxMileage),
        },
      watchlist: {
        ...filters.watchlist,
        options: filterAsUserSavedFilterDTO.watching ? [{ displayName: 'watching', value: filterAsUserSavedFilterDTO.watching }] : [],
      },
      carmigoInspected: {
        ...filters.watchlist,
        options: filterAsUserSavedFilterDTO.carmigoInspected ? [{ displayName: 'Carmigo Inspected', value: filterAsUserSavedFilterDTO.carmigoInspected }] : [],
      },
    }

    Object.keys(filterAsSelectedFilterDTO).forEach(filterFieldName => {
        let hasSelectedOptions = filterAsSelectedFilterDTO[filterFieldName as FilterFieldName]?.options?.length;
        if (!hasSelectedOptions) {
            delete filterAsSelectedFilterDTO[filterFieldName as FilterFieldName];
        }
    });

    return {
        ...userSavedFilterDTO,
        filter: filterAsSelectedFilterDTO
    }
}

export function mapStringArrayToFilterFieldOptionArray(stringArr: string | string[]): FilterFieldOption[] | undefined {
    if (typeof stringArr == 'string') {
        return [{ displayName: stringArr }];
    }
    return stringArr?.map(value => { return { displayName: value } });
}

export function getMinMaxAsFilterFieldOptionRangeArray(min: number | null, max: number | null): FilterFieldOptionRange[] | undefined {
    if (min == null || max == null) {
        return undefined;
    }
    let minValue: string | number = typeof min == 'string' ? parseInt(min) : min;
    let maxValue: string | number = typeof max == 'string' ? parseInt(max) : max;
    return [
        { displayName: 'min', value: minValue },
        { displayName: 'max', value: maxValue },
    ];
}

export function updateFilterUrlParams({ route, selectedOptions, selectedSavedFilterId, maintainParams=['listingType', 'savedFilter']}: {
    route: Route, 
    selectedOptions?: SelectedFilters,
    selectedSavedFilterId?: number,
    maintainParams?: string[], 
}) {
    let nonEmptySelectedOptionValues = getNonEmptySelectedOptionValues(selectedOptions);
    if (selectedSavedFilterId) {
        nonEmptySelectedOptionValues = {
            ...nonEmptySelectedOptionValues,
            savedFilter: selectedSavedFilterId.toString(),
        }
    }
    updateUrlParams({
        route,
        newQueryParams: nonEmptySelectedOptionValues,
        maintainParams,
    });
}

export function getNonEmptySelectedOptionValues(selectedOptions?: SelectedFilters) {
    if (!selectedOptions) {
        return {};
    }
    let nonEmptySelectedOptionValues: { [key: string]: string | string[] } = {};
    Object.keys(selectedOptions).forEach(fieldName => {
        let filterForFieldName = selectedOptions[fieldName as FilterFieldName];

      // consider boolean filter non-empty if bool value is not false
        let isBooleanFilterType = filterForFieldName?.config?.filterType == 'boolean';
        if (isBooleanFilterType && filterForFieldName?.options?.length && filterForFieldName?.options[0].value == false) { 
          return;
        }

        let values = filterForFieldName?.options?.map(option => option.value ?? option.displayName);
        if (values) {
            nonEmptySelectedOptionValues[fieldName] = values;
        }
    });
    return nonEmptySelectedOptionValues;
}

export function convertSelectedFiltersToUserSavedFilterDTO(filterName: string, selectedFilters: SelectedFilters) {
    function getMinOrMax(minOrMax: 'min' | 'max', options?: FilterFieldOption[]): number | null {
        if (!options?.length) {
            return null;
        }
        return parseInt(options[minOrMax == 'min' ? 0 : 1].value);
    }

    function getOptionsValues(options?: FilterFieldOption[]): string[] | null {
        if (!options?.length) {
            return null;
        }
        return options.map(value => value.value ?? value.displayName);
    }

    function isBooleanFilterSelected(options?: FilterFieldOption[]): boolean | null {
        if (!options?.length) {
            return null;
        }
        return Boolean(options[0].value) ?? null;
    }

    return {
        filterName,
        sellerType: getOptionsValues(selectedFilters.sellerType?.options),
        minYear: getMinOrMax('min', selectedFilters.year?.options),
        maxYear: getMinOrMax('max', selectedFilters.year?.options),
        make: getOptionsValues(selectedFilters.make?.options),
        model: getOptionsValues(selectedFilters.model?.options),
        bodyType: getOptionsValues(selectedFilters.bodyType?.options),
        trim: getOptionsValues(selectedFilters.trim?.options),
        distance: getMinOrMax('max', selectedFilters.distance?.options),
        distanceZip: '92020',
        minPrice: getMinOrMax('min', selectedFilters.price?.options),
        maxPrice: getMinOrMax('max', selectedFilters.price?.options),
        minMileage: getMinOrMax('min', selectedFilters.mileage?.options),
        maxMileage: getMinOrMax('max', selectedFilters.mileage?.options),
        fuelType: getOptionsValues(selectedFilters.fuelType?.options),
        watching: isBooleanFilterSelected(selectedFilters.watchlist?.options),
        carmigoInspected: isBooleanFilterSelected(selectedFilters.carmigoInspected?.options),
        sellerStore: getOptionsValues(selectedFilters.sellerStore?.options),
    }
}

export function checkForSavedFilterChanges(savedFilter: SelectedFilters, selectedFilters: SelectedFilters): boolean {
    const differentFields = !isEqual(Object.keys(savedFilter), Object.keys(selectedFilters));
    if (differentFields) {
        return true;
    }
    return Object.keys(savedFilter).some(filterFieldName => {
        let selectedFilterOptions = selectedFilters[filterFieldName as FilterFieldName]?.options?.map(option => option.value ?? option.displayName);
        let savedFiltersOptions = savedFilter[filterFieldName as FilterFieldName]?.options?.map(option => option.value ?? option.displayName);
        return !isEqual(selectedFilterOptions, savedFiltersOptions);
    });
}

export function getCustomFilterFieldOptionsConfigByFieldName(fieldName: FilterFieldName): FilterFieldOptionsSchemaConfig | undefined {
    switch (fieldName) {
        case 'sellerStore':
            return {
                customTable: 'store',
                customDisplayColumn: 'name',
                additionalGroupByParams: ['liquidMotorsDealerAlias.storeName', 'store.facilitatingAuctionId'],
            }
        default:
            return undefined;
    }
}

export function getFilterFieldOptionsSchema({ fieldFilterSchema, allFilterSchemas, customConfig }: {
    fieldFilterSchema: FilterSchema,
    allFilterSchemas: FilterSchema[],
    customConfig?: FilterFieldOptionsSchemaConfig
}): FilterFieldOptionsSchema {
    let groupByParam = fieldFilterSchema.property;
    let additionalGroupByParams: string[] = [];
    let [table, displayNameColumn]: string[] = fieldFilterSchema.property.split('.');
    let valueColumn: string | undefined = undefined;

    // handle custom config
    if (customConfig) {
        if (customConfig.customTable) {
            table = customConfig.customTable;
        }
        if (customConfig.customDisplayColumn) {
            displayNameColumn = customConfig.customDisplayColumn;
        }
        if (customConfig.customValueColumn) {
            valueColumn = customConfig.customValueColumn;
        }
        if (customConfig.additionalGroupByParams) {
            additionalGroupByParams = customConfig.additionalGroupByParams;
        }
    }

    return {
        modifiers: {
            filters: allFilterSchemas,
            groupBy: [groupByParam, ...additionalGroupByParams],
        },
        filterRequestType: fieldFilterSchema.property === 'store.name' ? 'store' : 'listing',
        dataRequest: {
            table,
            displayName: displayNameColumn,
            value: valueColumn
        }
    }

}

export function getModifierOptionsFilterObject(filterFieldName: FilterFieldName, values: any): ModifierOptionsFilterObject | undefined {
    switch(filterFieldName) {
        case 'make':
            return {
                property: 'vehicle.make',
                comparator: 'iLike',
                values,
            }
        case 'model':
            return {
                property: 'vehicle.model',
                comparator: 'iLike',
                values,
            }
        case 'trim':
            return {
                property: 'vehicle.trim',
                comparator: 'iLike',
                values,
            }
        case 'year': 
            return {
                property: 'vehicle.year',
                comparator: 'between',
                values,
            }
        case 'mileage':
            return {
                property: 'vehicleListing.mileage',
                comparator: 'between',
                values,
            }
        case 'bodyType': 
            return {
                property: 'vehicle.bodyType',
                comparator: 'iLike',
                values,
            }
        case 'fuelType': 
            return {
                property: 'bbInfo.fuelType',
                comparator: 'iLike',
                values,
            }
        case 'sellerType':
            return {
                property: 'sellerType.value',
                comparator: 'in',
                values,
            }
        case 'sellerStore':
            return {
                property: 'store.name',
                comparator: 'in',
                customFilterName: 'storeNameOrAlias',
                values,
            }
        case 'watchlist':
            return {
                property: 'watchlistSubquery.vehicleListingId',
                comparator: 'notNull',
                values: null,
            }
        case 'price':
            return {
                property: router.currentRoute.query.listingType === 'marketplace' ? 'ml.reservePrice' : 'highestBidSubSelect.highestbid',
                comparator: 'between',
                values,
            }
        case 'carmigoInspected':
            return {
                property: 'vehicleListing.inspectorPersonId',
                comparator: 'notNull',
                values: null,
            }
        case 'isInoperable':
            return {
                property: 'vehicleListingAttributes.isInoperable',
                comparator: '=',
                values: values[0], // true or false
            }
        case 'isWholesale':
            return {
                property: 'vehicleListingAttributes.isWholesale',
                comparator: '=',
                values: values[0], // true or false
            }
        case 'isFrontline':
            return {
                property: 'vehicleListingAttributes.isFrontline',
                comparator: '=',
                values: values[0], // true or false
            }
        case 'distance':
            return {
                property: 'zip',
                comparator: 'zip',
                values, // [zip: string, distance: number (miles)]
            }
    }
}

export function convertSelectedFiltersToModifierOptionsFilterObject(filter?: SelectedFilters): ModifierOptionsFilterObject[] {
    if (!filter) {
        return [];
    }
    let modifierObjects: ModifierOptionsFilterObject[] = [];
    // get non-empty SelectedFilters
    let nonEmptyFilters: SelectedFilters = getNonEmptySelectedOptionValues(filter);

    // format as ModifierOptionsFilterObject
    Object.keys(nonEmptyFilters).forEach((filterFieldName) => {
        let filterField: Partial<FilterField> | undefined = filter[filterFieldName as FilterFieldName];

        if (filterFieldName == 'zip' || !filterField?.options?.length) {
            return;
        }

        let filterValue = undefined;

        // if filter field is distance, format filter value
        if (filterFieldName == 'distance') {
            let distanceMin = filterField.options[0].value;
            let distanceMax = filterField.options[1].value;
            let zip = filter.zip?.options?.[0].value;
            if (distanceMin !== undefined && distanceMax !== undefined && zip !== undefined) {
                filterValue = [zip, distanceMin, distanceMax];
            } else {
                return;
            }
        }

        // otherwise format filter value
        filterValue = filterValue ?? filterField.options.map(option => option.value ?? option.displayName);

        // format as ModifierOptionsFilterObject
        let filterModifier = getModifierOptionsFilterObject(filterFieldName as FilterFieldName, filterValue);
        if (filterModifier) {
            modifierObjects.push(filterModifier);
        }
    });
    return modifierObjects;
}

export function sortFiltersWithBooleansFirst(filters: Filter): Filter {
    // sort keys by booleans first
    let sortedFilterKeys: FilterFieldName[] = [];
    Object.keys(filters).forEach(field => {
        let filterFieldName = field as FilterFieldName; 
        let filterType = filters[filterFieldName]?.config.filterType;
        filterType == 'boolean' 
            ? sortedFilterKeys.unshift(filterFieldName)
            : sortedFilterKeys.push(filterFieldName as FilterFieldName);
    });
    // return the sorted Filter
    let filtersWithBooleansFirst: Filter = {};
    sortedFilterKeys.forEach((filterFieldName: FilterFieldName) => {
        filtersWithBooleansFirst[filterFieldName] = filters[filterFieldName];
    });
    return filtersWithBooleansFirst;
}

// returns true if dependent fields are filled out, or false if not
export function checkDependentFields({ filterConfig }: {
    filterConfig: FilterFieldConfig,
}): boolean {
    if (!filterConfig.dependentFields?.length) {
        return true;
    }
    return false;

}

export function filterOutResultsWithCountZero(listingFilterResults: FilterFieldOption[]): FilterFieldOption[] {
    return listingFilterResults.filter(value => value.count);
}

export async function getFilterFieldOptions({ fieldName, selectedFilters, vehicleStatuses, loggedInPersonId, config }: {
    fieldName: FilterFieldName,
    selectedFilters: SelectedFilters,
    vehicleStatuses?: VehicleStatus[],
    loggedInPersonId?: number,
    config?: {
        dontFetchForFields?: FilterFieldName[],
        transformResults?: (listings: FilterFieldOption[]) => FilterFieldOption[],
    }
}): Promise<FilterFieldOption[] | void>  {
    // return early if shouldn't fetch for this field
    if (config?.dontFetchForFields?.includes(fieldName)) {
        return;
    }

    // get filterSchemas for payload
    let filterSchemas: ModifierOptionsFilterObject[] = convertSelectedFiltersToModifierOptionsFilterObject(selectedFilters);

    if (vehicleStatuses) {
        filterSchemas.push({
            property: 'vehicleStatus.value',
            comparator: 'in',
            values: vehicleStatuses,
        });
    }

    if (loggedInPersonId) {
        filterSchemas.push({
            property: 'vehicleListing.sellerId',
            comparator: '!=',
            values: loggedInPersonId,
        });
    }

    // get fieldName's filterSchema to format payload (table/column names)
    let selectedFilterSchema = getModifierOptionsFilterObject(fieldName, null);
    if (!selectedFilterSchema) {
        return;
    }

    // format payload
    let payload: FilterFieldOptionsSchema = getFilterFieldOptionsSchema({
        fieldFilterSchema: selectedFilterSchema,
        allFilterSchemas: filterSchemas,
        customConfig: getCustomFilterFieldOptionsConfigByFieldName(fieldName),
    });

    // get the listings
    let listings = await POST(`/filters/filterSearchTerms`, payload)
        .then(res => res.data)
        .catch(error => {
            throw error;
        });

    // transform results if needed (e.g., filterOutResultsWithCountZero)
    return config?.transformResults 
        ? config.transformResults(listings)
        : listings;
}

export function applyFilterMask(value: any, mask?: InputMask) {
    switch(mask) {
        case 'currency':
            return value ? toCurrency(value) : '$0';
        default: 
            return value;
    }
}

export function fireFilterAnalytics({ field, value, type='tag' }: { 
    field: string, 
    value: FilterFieldOption | FilterFieldOption[], 
    type?: FilterInputType,
}) {
    let filterValue = (value as FilterFieldOption)?.displayName;

    if (type == 'range') {
        let minMaxArray = value as FilterFieldOption[];
        filterValue = `${minMaxArray[0].value}-${minMaxArray[1].value}`;
    }     

    fireEvent(`Filter Option - ${field}: ${filterValue}`);
}